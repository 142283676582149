<template>
  <div id="app">
    <nav class="navbar">
      <!-- Firma İsmi Ortada -->
      <div class="navbar-brand">Soru Çözümleri</div>
      
      <div class="navbar-links">
        <router-link to="/student" class="nav-link" v-if="isLoggedIn">Öğrenci Paneli</router-link>
        <router-link to="/admin" class="nav-link" v-if="isAdmin">Admin Panel</router-link>
        <button v-if="isLoggedIn" @click="logout" class="logout-button">Çıkış Yap</button>
        <button v-else @click="showLogin" class="login-button">Giriş Yap</button>
      </div>
    </nav>
    
    <!-- Login Popup -->
    <div v-if="showLoginPopup" class="login-popup">
      <div class="login-box">
        <h2>Giriş Yap</h2>
        <form @submit.prevent="handleLogin" class="login-form">
          <input 
            v-model="loginForm.email" 
            type="email" 
            placeholder="Email" 
            required 
            class="login-input"
          >
          <input 
            v-model="loginForm.password" 
            type="password" 
            placeholder="Şifre" 
            required 
            class="login-input"
          >
          <button type="submit" class="login-button">Giriş Yap</button>
        </form>
        <button @click="showLoginPopup = false" class="close-popup">Kapat</button>
      </div>
    </div>

    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      isAdmin: false,
      isLoggedIn: false,
      showLoginPopup: false,
      loginForm: {
        email: '',
        password: ''
      }
    }
  },
  methods: {
    showLogin() {
      this.showLoginPopup = true;
    },
    async handleLogin() {
      try {
        // API call yapılacak
        // const response = await apiService.login(this.loginForm);
        // localStorage.setItem('token', response.data.token);

        // Geçici olarak direct login
        localStorage.setItem('token', 'dummy-token');
        this.isLoggedIn = true;
        this.isAdmin = this.loginForm.email.includes('admin');
        this.showLoginPopup = false; // Popup'ı kapat
        this.$router.push(this.isAdmin ? '/admin' : '/student');
      } catch (error) {
        console.error('Login error:', error);
        alert('Giriş başarısız!');
      }
    },
    logout() {
      localStorage.removeItem('token');
      this.isLoggedIn = false;
      this.isAdmin = false;
      this.$router.push('/');
    }
  },
  mounted() {
    const token = localStorage.getItem('token');
    if (token) {
      this.isLoggedIn = true;
      this.isAdmin = true; // veya API'den role bilgisi alınabilir
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #4CAF50;
  padding: 1rem;
}

.navbar-brand {
  font-size: 1.25rem;
  color: white;
  font-weight: bold;
  text-align: center;
  flex: 1;
}

.navbar-links {
  display: flex;
  align-items: center;
}

.nav-link {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  margin-right: 1rem;
}

.logout-button, .login-button {
  margin-left: auto;
  background: transparent;
  border: 1px solid white;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}

/* Login Popup */
.login-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6); /* Arka planı biraz daha koyu */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.login-box {
  background: white;
  padding: 1.5rem 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 400px;
  text-align: center;
}

.login-box h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333; /* Başlık rengini belirginleştirdik */
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.login-input {
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

.login-button {
  background: #4CAF50;
  color: white;
  border: none;
  padding: 0.75rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.close-popup {
  margin-top: 1rem;
  background: #d9534f;
  color: white;
  border: none;
  padding: 0.5rem;
  border-radius: 5px;
  cursor: pointer;
}

/* Responsive Design */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    gap: 0.5rem;
  }

  .logout-button, .login-button {
    margin: 0.5rem 0;
  }
}
</style>
