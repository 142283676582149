import { createRouter, createWebHistory } from 'vue-router';
import HelloWorld from '../components/HelloWorld.vue';
import StudentView from '../views/StudentView.vue';

const routes = [
  {
    path: '/',
    redirect: '/student'
  },
  {
    path: '/admin',
    name: 'admin',
    component: HelloWorld
  },
  {
    path: '/student',
    name: 'student',
    component: StudentView
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;