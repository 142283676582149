<template>
  <div>
    <!-- Kategori Ekleme Formu -->
    <form @submit.prevent="addCategory" class="mb-4 flex items-center">
      <input v-model="newCategoryName" placeholder="Yeni Kategori İsmi" required class="input mr-2 flex-grow">
      <button type="submit" class="btn btn-primary">Kök Kategori Ekle</button>
    </form>

    <!-- Arama Girdisi -->
    <input v-model="searchQuery" placeholder="Kategorilerde Ara" class="input mb-4 w-full">

    <!-- Kategoriler Listesi -->
    <ul class="category-list">
      <CategoryItem
        v-for="category in filteredCategories"
        :key="category._id"
        :category="category"
        @add-subcategory="openAddSubcategoryModal"
        @delete-category="deleteCategory"
        @upload-excel="openUploadModal"
        @view-contents="viewCategoryContents"
        @edit-category="openEditCategoryModal"
      />
    </ul>

    <!-- İçerik Görüntüleme Modali -->
    <div v-if="showContentsModal" class="modal">
      <div class="modal-content">
        <span @click="closeContentsModal" class="close-button">&times;</span>
        <h3>Kategori İçerikleri</h3>
        <div v-if="categoryContents && categoryContents.length > 0" class="content-list">
          <div v-for="content in categoryContents" :key="content._id" class="content-item">
            <span>{{ content.title }}</span>
            <button @click="deleteContent(content._id)" class="btn btn-danger btn-sm">Sil</button>
          </div>
        </div>
        <div v-else>
          Bu kategoride içerik bulunmamaktadır.
        </div>
      </div>
    </div>

    <!-- Kategori Düzenleme Modali -->
    <div v-if="showEditCategoryModal" class="modal">
      <div class="modal-content">
        <span @click="closeEditCategoryModal" class="close-button">&times;</span>
        <h3>Kategori Düzenle</h3>
        <form @submit.prevent="updateCategory">
          <input v-model="newCategoryName" placeholder="Kategori İsmi" required class="input mr-2">
          <button type="submit" class="btn btn-primary">Kaydet</button>
        </form>
      </div>
    </div>

    <!-- Alt Kategori Ekleme Modali -->
    <div v-if="showSubcategoryModal" class="modal">
      <div class="modal-content">
        <span @click="closeAddCategoryModal" class="close-button">&times;</span>
        <h3>Alt Kategori Ekle</h3>
        <form @submit.prevent="addCategory">
          <input v-model="newCategoryName" placeholder="Alt Kategori İsmi" required class="input mr-2">
          <button type="submit" class="btn btn-primary">Ekle</button>
        </form>
      </div>
    </div>

    <!-- Excel Yükleme Modali -->
    <div v-if="showUploadModal" class="modal">
      <div class="modal-content">
        <span @click="closeUploadModal" class="close-button">&times;</span>
        <h3>Excel Dosyası Yükle</h3>
        <form @submit.prevent="uploadExcelFile">
          <input type="file" @change="handleFileChange" accept=".xlsx, .xls" required />
          <button type="submit" class="btn btn-primary">Yükle</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import apiService from '@/apiService';
import CategoryItem from '@/components/CategoryItem.vue';

export default {
  components: {
    CategoryItem,
  },
  data() {
    return {
      categories: [],
      newCategoryName: '',
      showSubcategoryModal: false,
      showUploadModal: false,
      showContentsModal: false,
      showEditCategoryModal: false,
      parentId: null,
      searchQuery: '',
      selectedCategoryId: null,
      selectedFile: null,
      categoryContents: [],
    };
  },
  computed: {
    filteredCategories() {
      const filterCategories = (categories) => {
        return categories
          .map((category) => {
            const matchedChildren = category.children ? filterCategories(category.children) : [];
            if (
              category.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
              matchedChildren.length
            ) {
              return {
                ...category,
                children: matchedChildren,
              };
            }
            return null;
          })
          .filter((category) => category !== null);
      };
      return filterCategories(this.categories);
    },
  },
  methods: {
    async fetchCategories() {
      try {
        const response = await apiService.getCategories();
        const flatCategories = response.data.data;
        this.categories = this.buildCategoryTree(flatCategories);
      } catch (error) {
        console.error('Kategoriler getirilirken hata:', error);
      }
    },

    async addCategory() {
      try {
        const category = {
          name: this.newCategoryName,
          parent_category: this.parentId,
        };
        await apiService.addCategory(category);
        this.newCategoryName = '';
        this.closeAddCategoryModal();
        await this.fetchCategories();
      } catch (error) {
        console.error('Kategori eklenirken hata:', error);
      }
    },

    async updateCategory() {
  try {
    const category = {
      name: this.newCategoryName,
    };
    await apiService.updateCategory(this.selectedCategoryId, category);
    this.newCategoryName = '';
    this.closeEditCategoryModal();
    // Kategorileri güncelledikten sonra tüm listeyi tekrar yükleyin
    await this.fetchCategories();
  } catch (error) {
    console.error('Kategori düzenlenirken hata:', error);
  }
},

    openEditCategoryModal(categoryId, currentName) {
      this.selectedCategoryId = categoryId;
      this.newCategoryName = currentName;
      this.showEditCategoryModal = true;
    },

    closeEditCategoryModal() {
      this.showEditCategoryModal = false;
      this.selectedCategoryId = null;
      this.newCategoryName = '';
    },

    
    async deleteCategory(id) {
      try {
        if (this.hasSubcategories(id)) {
          alert('Bu kategori alt kategorilere sahip. Önce alt kategorileri silmelisiniz.');
          return;
        }

        const hasContent = await this.checkCategoryHasContent(id);
        if (hasContent) {
          alert('Bu kategori içeriklere sahip. Önce içerikleri silmelisiniz.');
          return;
        }

        const isConfirmed = window.confirm('Bu kategoriyi silmek istediğinize emin misiniz?');
        if (!isConfirmed) {
          return;
        }

        await apiService.deleteCategory(id);
        await this.fetchCategories();
        alert('Kategori başarıyla silindi.');
      } catch (error) {
        console.error('Kategori silinirken hata:', error);
        alert('Kategori silinirken bir hata oluştu.');
      }
    },

    // Content Operations
    async viewCategoryContents(categoryId) {
    try {
      console.log('Fetching contents for category:', categoryId);
      const response = await apiService.getCategoryContent(categoryId);
      
      this.categoryContents = response.data.data.filter(content => {
        const contentCategoryId = content.category?._id || content.category;
        return contentCategoryId === categoryId;
      });
      
      this.selectedCategoryId = categoryId;
      this.showContentsModal = true;
    } catch (error) {
      console.error('İçerikler getirilirken hata:', error);
      alert('İçerikler getirilirken bir hata oluştu.');
      }
    },

    async deleteContent(contentId) {
    try {
      const isConfirmed = window.confirm('Bu içeriği silmek istediğinize emin misiniz?');
      if (!isConfirmed) return;

      await apiService.deleteContent(contentId);
      // Refresh contents after deletion
      await this.viewCategoryContents(this.selectedCategoryId);
      alert('İçerik başarıyla silindi.');
    } catch (error) {
      console.error('İçerik silinirken hata:', error);
      alert('İçerik silinirken bir hata oluştu.');
      }
    },

    // Helper Methods
    buildCategoryTree(categories) {
      const map = {};
      categories.forEach((cat) => {
        cat.children = [];
        map[cat._id] = cat;
      });
      const tree = [];
      categories.forEach((cat) => {
        if (cat.parent_category) {
          map[cat.parent_category]?.children.push(cat);
        } else {
          tree.push(cat);
        }
      });
      return tree;
    },

    hasSubcategories(categoryId) {
      const findCategory = (categories, id) => {
        for (const category of categories) {
          if (category._id === id) {
            return category.children && category.children.length > 0;
          }
          if (category.children) {
            const found = findCategory(category.children, id);
            if (found !== null) return found;
          }
        }
        return null;
      };
      return findCategory(this.categories, categoryId);
    },

    async checkCategoryHasContent(categoryId) {
    try {
      const response = await apiService.getCategoryContent(categoryId);
      const contents = response.data.data.filter(content => {
        const contentCategoryId = content.category?._id || content.category;
        return contentCategoryId === categoryId;
      });
      return contents.length > 0;
    } catch (error) {
      console.error('İçerik kontrolü sırasında hata:', error);
      return false;
      }
    },

    // Modal Methods
    openAddSubcategoryModal(parentId) {
      this.parentId = parentId;
      this.showSubcategoryModal = true;
    },

    closeAddCategoryModal() {
      this.showSubcategoryModal = false;
      this.parentId = null;
      this.newCategoryName = '';
    },

    openUploadModal(categoryId) {
      this.selectedCategoryId = categoryId;
      this.showUploadModal = true;
    },

    closeUploadModal() {
      this.showUploadModal = false;
      this.selectedCategoryId = null;
      this.selectedFile = null;
    },

    closeContentsModal() {
      this.showContentsModal = false;
      this.categoryContents = [];
      this.selectedCategoryId = null;
    },

    // File Upload Methods
    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
    },

    async uploadExcelFile() {
      if (!this.selectedFile || !this.selectedCategoryId) {
        alert('Lütfen bir dosya seçin ve kategori belirleyin.');
        return;
      }

      const formData = new FormData();
      formData.append('excelFile', this.selectedFile);
      formData.append('category', this.selectedCategoryId);

      try {
        const response = await apiService.uploadExcel(formData);
        console.log('Upload response:', response.data);
        alert('Dosya başarıyla yüklendi.');
        this.closeUploadModal();
      } catch (error) {
        console.error('Upload error details:', {
          status: error.response?.status,
          statusText: error.response?.statusText,
          data: error.response?.data
        });
        alert('Dosya yüklenirken hata oluştu.');
      }
    },
  },
  mounted() {
    this.fetchCategories();
  },
};
</script>

<style scoped>
.category-list {
  padding-left: 0; /* Daha temiz bir görünüm için sol girinti kaldırıldı */
  list-style-type: none;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  min-width: 300px;
}
.close-button {
  float: right;
  cursor: pointer;
  font-size: 24px;
}
.input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.btn {
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}
.btn-primary {
  background: #4CAF50;
  color: white;
  border: none;
}
.btn-danger {
  background: #f44336;
  color: white;
  border: none;
}
.content-list {
  max-height: 400px;
  overflow-y: auto;
}
.content-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}
</style>