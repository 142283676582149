<template>
  <div class="student-container">
    <button class="sidebar-toggle" @click="toggleSidebar">☰ Menü</button>
    <div class="sidebar" :class="{ open: isSidebarOpen }">
      <h2>Kategoriler</h2>
      <ul class="category-list">
        <CategoryTreeItem
          v-for="category in categories"
          :key="category._id"
          :category="category"
          :selectedId="selectedCategory"
          @select="handleSelect"
        />
      </ul>
    </div>

    <div class="content">
      <div v-if="selectedCategory" class="video-grid">
        <div v-for="content in categoryContents" :key="content._id" class="content-card">
          <h3>{{ content.title }}</h3>
          <div v-if="content.type === 'video'">
            <div style="padding:56.25% 0 0 0;position:relative;">
              <iframe 
                :src="convertToEmbedUrl(content.url)"
                frameborder="0"
                allow="autoplay; fullscreen"
                style="position:absolute;top:0;left:0;width:100%;height:100%;"
                :title="content.title"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <a v-else-if="content.type === 'document'" :href="content.url" target="_blank" class="document-link">
            Dökümanı Görüntüle
          </a>
        </div>
      </div>
      <div v-else class="no-selection">
        Lütfen içerikleri görüntülemek için bir kategori seçin
      </div>
    </div>
  </div>
</template>

<script>
import apiService from '@/apiService';
import CategoryTreeItem from '../components/CategoryTreeItem.vue';

export default {
  components: {
    CategoryTreeItem
  },
  data() {
    return {
      categories: [],
      selectedCategory: null,
      categoryContents: [],
      isSidebarOpen: false
    };
  },
  methods: {
    convertToEmbedUrl(vimeoUrl) {
      const matches = vimeoUrl.match(/vimeo\.com\/(\d+)(?:\/(\w+))?/);
      if (matches && matches[1]) {
        const videoId = matches[1];
        const hash = matches[2] ? `?h=${matches[2]}` : '';
        const embedUrl = `https://player.vimeo.com/video/${videoId}${hash}`;
        console.log("Video Embed URL:", embedUrl);
        return embedUrl;
      }
      console.warn("Geçersiz Vimeo URL'si:", vimeoUrl);
      return '';
    },
    async fetchCategories() {
      try {
        const response = await apiService.getCategories();
        this.categories = this.buildCategoryTree(response.data.data);
      } catch (error) {
        console.error('Kategoriler yüklenirken hata:', error);
      }
    },
    buildCategoryTree(categories) {
      const map = {};
      categories.forEach(cat => {
        cat.children = [];
        map[cat._id] = cat;
      });
      
      const tree = [];
      categories.forEach(cat => {
        if (cat.parent_category) {
          map[cat.parent_category]?.children.push(cat);
        } else {
          tree.push(cat);
        }
      });
      return tree;
    },
    async handleSelect(categoryId) {
      this.selectedCategory = categoryId;
      try {
        const response = await apiService.getCategoryContent(categoryId);
        this.categoryContents = response.data.data.filter(content =>
          content.category === categoryId ||
          (content.category && content.category._id === categoryId)
        );
      } catch (error) {
        console.error('İçerikler yüklenirken hata:', error);
      }
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    }
  },
  mounted() {
    this.fetchCategories();
  }
};
</script>

<style scoped>
.student-container {
  display: flex;
  height: calc(100vh - 60px);
}

.sidebar {
  width: 300px;
  background: #f5f5f5;
  padding: 1rem;
  border-right: 1px solid #ddd;
  overflow-y: auto;
}

.category-list {
  list-style: none;
  padding: 0;
}

.content {
  flex: 1;
  padding: 1rem;
  overflow-y: auto;
}

.video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
}

.content-card {
  background: white;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.video-player {
  width: 100%;
  border-radius: 4px;
}

.document-link {
  display: block;
  padding: 0.5rem;
  text-align: center;
  background: #2196F3;
  color: white;
  border-radius: 4px;
  text-decoration: none;
}

.no-selection {
  text-align: center;
  color: #666;
  margin-top: 2rem;
}

/* Responsive Design for StudentView */
@media (max-width: 768px) {
  .student-container {
    flex-direction: column;
    height: auto;
  }

  .sidebar {
    width: 100%;
    max-height: 300px; /* Scroll için sınırlama */
    overflow-y: auto;
    padding: 1rem;
    border-bottom: 1px solid #ddd;
    display: none; /* Varsayılan olarak gizlenmiş */
    position: relative;
    background: #f5f5f5;
  }

  .sidebar.open {
    display: block; /* Açıldığında görünecek */
  }

  .sidebar-toggle {
    display: block;
    background: #4CAF50;
    color: white;
    border: none;
    padding: 0.75rem;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    width: 100%;
    margin-bottom: 1rem;
  }

  .content {
    padding: 1rem;
  }
}
</style>