<!-- src/components/CategoryTreeItem.vue -->
<template>
  <li>
    <div 
      class="category-item"
      :class="{ active: selectedId === category._id }"
    >
      <div class="category-content" @click="selectCategory(category._id)">
        <span v-if="hasChildren" class="expand-icon" @click.stop="toggle">
          {{ isExpanded ? '▼' : '▶' }}
        </span>
        <span class="category-name">{{ category.name }}</span>
      </div>
    </div>
    
    <ul v-if="hasChildren && isExpanded" class="nested-list">
      <CategoryTreeItem
        v-for="child in category.children"
        :key="child._id"
        :category="child"
        :selectedId="selectedId"
        @select="$emit('select', $event)"
      />
    </ul>
  </li>
</template>

<script>
export default {
  name: 'CategoryTreeItem',
  props: {
    category: { type: Object, required: true },
    selectedId: { type: String, default: null }
  },
  data() {
    return {
      isExpanded: false
    }
  },
  computed: {
    hasChildren() {
      return this.category.children && this.category.children.length > 0
    }
  },
  methods: {
    toggle() {
      this.isExpanded = !this.isExpanded
    },
    selectCategory(id) {
      this.$emit('select', id)
    }
  }
}
</script>
  
  <style scoped>
  .category-item-container {
    margin: 0.2rem 0;
  }
  
  .category-item {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .category-item:hover {
    background: #e0e0e0;
  }
  
  .category-item.active {
    background: #4CAF50;
    color: white;
  }
  
  .expand-icon {
    margin-right: 0.5rem;
    font-size: 0.8rem;
    width: 1rem;
  }
  
  .category-name {
    flex: 1;
  }
  
  .children {
    margin-left: 1.5rem;
  }
  </style>