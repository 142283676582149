import axios from 'axios';

const apiClient = axios.create({
  baseURL: 'https://api.cozizle.com/api', // Adjust the base URL as needed
  //baseURL: 'http://localhost:3000/api', // Adjust the base URL as needed

  headers: {
    'Content-Type': 'application/json'
  }
});

apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default {
  getCategories() {
    return apiClient.get('/categories');
  },
  addCategory(category) {
    return apiClient.post('/categories/add', category);
  },
  updateCategory(id, category) {
    return apiClient.put(`/categories/update/${id}`, category);
  },
  deleteCategory(id) {
    return apiClient.delete(`/categories/delete/${id}`);
  },
  getCategoryContent(categoryId) {
    return apiClient.get(`/content?category=${categoryId}`);
  },
  
  deleteMultipleCategories(ids) {
    return apiClient.post('/categories/delete-multiple', { ids });
  },
  deleteContent(contentId) {
    return apiClient.delete(`/content/delete/${contentId}`);
  },

  uploadExcel(formData) {
    return apiClient.post('/content/bulk-upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  
};