<template>
  <li>
    <div class="flex items-center category-item">
      <!-- Expand/Collapse Icon -->
      <div @click="toggle" class="cursor-pointer flex items-center">
        <span v-if="hasChildren" class="mr-2">
          <svg v-if="isExpanded" xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4"/>
          </svg>
          <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4"/>
          </svg>
        </span>
        <span>{{ category.name }}</span>
      </div>
      <!-- Action Buttons -->
      <div class="ml-auto">
        <button @click="$emit('add-subcategory', category._id)" class="btn btn-sm btn-primary mr-2">
          Alt Kategori Ekle
        </button>
        <button @click="$emit('upload-excel', category._id)" class="btn btn-sm btn-secondary mr-2">
          Yükle
        </button>
        <button @click="$emit('view-contents', category._id)" class="btn btn-sm btn-info mr-2">
          İçerikleri Göster
        </button>
        <button @click="$emit('edit-category', category._id, category.name)" class="btn btn-sm btn-primary mr-2">
          Düzenle
        </button>
        <button @click="$emit('delete-category', category._id)" class="btn btn-sm btn-danger">
          Delete
        </button>
      </div>
    </div>
    <!-- Subcategories List -->
    <transition name="accordion">
      <ul v-show="isExpanded" v-if="hasChildren">
        <CategoryItem
          v-for="child in category.children"
          :key="child._id"
          :category="child"
          @add-subcategory="$emit('add-subcategory', $event)"
          @delete-category="$emit('delete-category', $event)"
          @upload-excel="$emit('upload-excel', $event)"
          @view-contents="$emit('view-contents', $event)"
          @edit-category="$emit('edit-category', $event)"
        />
      </ul>
    </transition>
  </li>
</template>

<script>
export default {
  name: 'CategoryItem',
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  computed: {
    hasChildren() {
      return this.category.children && this.category.children.length > 0;
    },
  },
  methods: {
    toggle() {
      if (this.hasChildren) {
        this.isExpanded = !this.isExpanded;
      }
    },
  },
};
</script>

<style scoped>
.flex {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
}
.cursor-pointer {
  cursor: pointer;
}
.ml-auto {
  margin-left: auto;
}
.btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.btn-primary {
  background-color: #4CAF50;
  color: white;
}
.btn-secondary {
  background-color: #2196F3;
  color: white;
}
.btn-info {
  background-color: #00BCD4;
  color: white;
}
.btn-danger {
  background-color: #f44336;
  color: white;
}
.mr-2 {
  margin-right: 0.5rem;
}
/* Accordion Transition */
.accordion-enter-active,
.accordion-leave-active {
  transition: max-height 0.3s ease;
}
.accordion-enter,
.accordion-leave-to {
  max-height: 0;
  overflow: hidden;
}
/* Alt kategoriler için girinti */
li > ul {
  padding-left: 1rem;
}
.category-item {
  margin-bottom: 2px; /* Kategoriler arasında daha az boşluk bırakmak */
  border-bottom: 1px solid #f0f0f0; /* Hafif bir çizgiyle kategorileri ayırmak */
}
</style>
